import type { NavLink } from "types/globals";

export const NAV_LINKS = [
  {
    key: "home",
    name: "Indeed Design",
    to: "/",
    type: "primary",
  },
  {
    key: "articles",
    name: "Articles",
    to: "/articles/",
    type: "secondary",
  },
  {
    key: "brandParent",
    name: "Brand guidelines",
    to: null,
    type: "secondary",
    children: [
      {
        key: "brand",
        name: "Overview",
        to: "/brand/",
        type: "tertiary",
      },
      {
        key: "logo",
        name: "Logo",
        to: "/brand/logo/",
        type: "tertiary",
      },
      {
        key: "color",
        name: "Color",
        to: "/brand/color/",
        type: "tertiary",
      },
      {
        key: "typography",
        name: "Typography",
        to: "/brand/typography/",
        type: "tertiary",
      },
      {
        key: "voicetone",
        name: "Voice and tone",
        shortenedName: "Voice",
        to: "/brand/voicetone/",
        type: "tertiary",
      },
      {
        key: "imagery",
        name: "Imagery",
        to: "/brand/imagery/",
        type: "tertiary",
      },
      {
        key: "illustration",
        name: "Illustration",
        to: "/brand/illustration/",
        type: "tertiary",
      },
      {
        key: "shape",
        name: "Shape",
        to: "/brand/shape/",
        type: "tertiary",
      },
      {
        key: "motion",
        name: "Motion",
        to: "/brand/motion/",
        type: "tertiary",
      },
      {
        key: "productrepresentation",
        name: "Product representation",
        shortenedName: "Product",
        to: "/brand/productrepresentation/",
        type: "tertiary",
      },
      {
        key: "iconography",
        name: "Iconography",
        to: "/brand/iconography/",
        type: "tertiary",
      },

      {
        key: "resources",
        name: "Resources",
        to: "/brand/resources/",
        type: "tertiary",
      },
    ],
  },
  {
    key: "jobs",
    name: "Jobs",
    to: "https://www.indeed.com/cmp/Indeed/jobs",
    type: "external",
  },
] as NavLink[];
